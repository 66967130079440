@font-face {
  font-family: 'kanit';
  font-style: normal;
  font-weight: normal;
  src:  url('../public/Font/Kanit-Light.ttf');
}

body {
  margin: 0px;
  padding: 0px;
  /* font-family: "Nunito Sans", sans-serif; */
  font-family: 'kanit'!important;
}
* {
  box-sizing: border-box;
}

.gallery-img,.gallery-img img{
  height: 215px;
}
.owl-home .featured-wrapper img{
height:350px;
object-fit: cover;
}


a {
  text-decoration: none !important;
}
ul,
li {
  margin: 0px !important;
  padding: 0px !important;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'kanit'!important;
}
/* add css mk start */

.service-section .owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: flex;
}
.branches-sec .owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.color-logo {
  color: #56b13d;
}
.pbmit-heading-subheading .pbmit-title{
  font-family: 'kanit'!important;
}
.who-we-are .pbmit-heading-subheading .pbmit-title {
  color: #fff;
}
.who-we-are .pbmit-heading-subheading {
  margin: auto;
  margin-bottom: 50px;
}
.copyright-text {
  color: #fff;
  font-size:14px
}
.copyright-text a {
  color: #8cc63f;
}
.text-undline{
  text-decoration: underline;
  font-size: 21px !important;
}
/* add css mk stop */
.challenges-content {
  margin-top: 3rem;
}
.mobsvg ul li svg {
  display: none;
}
/* header css start */
.site-branding {
  display: table;
  vertical-align: middle;
}
/* .site-branding a {
  display: table-cell;
  vertical-align: middle;
} */
.navbar-brand {
  position: relative;
}
.navbar-brand img {
  display: none;
  position: absolute;
  width: 85px;
  top: -29px;
  height: 68px;
}
.site-branding img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  max-height: 115px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.header-box {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  position: relative;
  padding: 0px 0px 0px 65px;
}
.header-box span {
  display: block;
}
.header-icon svg {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 32px;
  text-align: center;
  color: #8cc63f;
}
.header-box-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #60626d;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
}
.header-box-content {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #131419;
  text-transform: none;
  font-weight: 700;
  font-style: normal;
}
.header-box:last-child {
  margin-right: 0px;
}
.header .site-header-menu {
  margin-bottom: -34px;
  z-index: 2;
  position: relative;
}
.navigation {
  position: relative;
  border-radius: 0 0 30px;
  flex-grow: 1;
  padding: 6px 0px;
}
.navigation:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1500px;
  right: 0;
  top: 0;
  background-color: #75b727;
  z-index: -1;
  border-radius: 0px 0px 30px 0px;
}

.battery img{
height: 350px;
object-fit: cover;
}

.navigation button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}
.menu-right-box {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.header-button a {
  padding: 21px 60px;
  border-radius: 30px 0;
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  text-transform: uppercase;
  border: none;
  color: #fff;
  letter-spacing: 0.2px;
  background-color: #101010;
  font-size: 13px;
}
.header-button a svg {
  font-size: 25px;
  margin-left: 10px;
}
.header-button a:hover {
  background-color: #8cc63f;
  color: #fff;
  border-radius: 0 20px 0 0;
}
.navigation .nav-link {
  color: #fff;
  margin-right: 20px;
  text-transform: uppercase;
}
.navigation .nav-link:focus {
  color: #fff;
}
.navigation .right-box {
  padding-right: 40px;
}
.navigation .right-box svg {
  color: #fff;
  font-size: 22px;
}
/* header css end */

/* Slider css start */
.banner .carousel-item {
  height: 650px;
}
.banner .carousel-item img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  min-height: 720px;
}
.banner .carousel-control-next,
.carousel-control-prev {
  position: absolute !important;
  bottom: 100px !important;
  top: 82% !important;
  z-index: 1 !important;
  width: 50px !important;
  height: 50px;
  padding: 0 !important;
  color: #fff !important;
  text-align: center !important;
  background: rgba(255, 255, 255, 0.2) !important;
  border: 0 !important;
  opacity: 1 !important;
  transition: opacity 0.15s ease !important;
}
.banner .carousel-control-next:hover,
.banner .carousel-control-prev:hover {
  background-color: #56b13d !important;
}
.banner .carousel-control-prev {
  left: 85% !important;
}
.banner .carousel-control-next {
  right: 5% !important;
}
.banner .carousel-caption h2{
  font-family: 'kanit'!important;
  /* position: absolute;
  bottom: 0px; */
  text-shadow: 0px 0px 5px #000;
  /* background: #00000094; */
}
.banner .carousel-caption h6{
  font-family: 'kanit'!important;
  /* position: absolute;
  bottom: -85px; */
  text-shadow: 0px 0px 5px #000;
  /* background: #00000094; */
}
/* .banner .carousel-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1;
} */
.banner .carousel-item:after {
  position: absolute;
  content: "";
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  width: calc(100% - 120px);
  height: calc(100% - 120px);
  left: 60px;
  top: 60px;
  z-index: 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}
.banner .carousel-caption {
  z-index: 1;
}
.banner .carousel-caption h2 {
  font-size: 37px;
  line-height: 50px;
  text-transform: uppercase;
}
.banner .carousel-caption h6 {
  text-transform: uppercase;
  margin: 30px 0px;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.1, 1.1);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.1, 1.1);
  }
}
.slider-btn button {
  padding: 19px 32px;
  background-color: #56b13d;
  border: none;
  color: #fff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.8px;
  font-weight: 600;
  text-align: center;
  border-radius: 0px;
  text-transform: uppercase;
  z-index: 1;
  transition: all 500ms ease;
}
.slider-btn button svg {
  font-size: 20px;
  margin-right: 8px;
}
.slider-btn button:before {
  background-color: #1e2434;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: 110%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}
.slider-btn button:hover:before {
  top: -40%;
}
.slider-btn button:hover {
  color: #fff !important;
}
.banner .carousel-caption {
  bottom: 9.25rem;
}
.banner .carousel-item img {
  -webkit-animation: zoom 20s;
  animation: zoom 20s;
}
.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.two {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.three {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/* Slider css end */

/* about css start */

.about-sec {
  padding: 70px 0px;
}
.about-img {
  position: relative;
}
.about-img img {
  width: 100%;
  max-height: 415px;
}
.about-img img.years {
  bottom: 0;
  left: 0;
  position: absolute;
}
.about_content {
  padding: 10px 0px 0px 60px;
  position: relative;
}
.about_content h3.heading {
  color: #57b33e;
  font-size: 20px;
  margin: 12px 0 0;
}
.about_content h3.heading span {
  padding-left: 10px;
  position: relative;
  z-index: 1;
}
.about_content h3.heading span:before {
  background-color: currentColor;
  content: "";
  display: inline-block;
  height: 1.1em;
  left: -10px;
  position: relative;
  top: 4px;
  width: 2px;
  z-index: 1;
}
.about_content h2 {
  color: #232323;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 20px;
}
.about_content p {
  font-size: 15px;
  text-align: justify;
  z-index: 1;
  margin-top: 55px;
}
.about_content a {
  color: #000;
}
.about_content h1.sub-heading {
  color: hsla(0, 0%, 100%, 0);
  font-size: 144px;
  font-weight: 800;
  left: 32px;
  line-height: 100%;
  position: absolute;
  text-transform: uppercase;
  top: -31px;
  z-index: -1;
}
.about_content h1.sub-heading span {
  -webkit-text-stroke: 1px #d6d6d6;
}
.about_content a {
  padding: 15px 34px;
  border-radius: 30px 0;
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  text-transform: uppercase;
  border: none;
  color: #fff;
  letter-spacing: 0.2px;
  background-color: #101010;
  font-size: 15px;
  margin-top: 18px;
}
.about_content a:hover {
  background-color: #8cc63f;
  color: #fff;
  border-radius: 0 20px 0 0;
}

/* about css end */
/* service section start */

.service-section {
  background-color: #eff3f5;
  padding-top: 100px;
  padding-bottom: 80px;
}
.service-section .center-align {
  width: 54%;
  margin: auto;
  margin-bottom: 50px;
}
.pbmit-heading-subheading .pbmit-subtitle {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.8px;
  color: #101010;
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
}
.pbmit-heading-subheading h4.pbmit-subtitle {
  position: relative;
  display: inline-block;
  padding-left: 20px;
}
.pbmit-heading-subheading h4.pbmit-subtitle:before {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #8cc63f;
}
.pbmit-heading-subheading .pbmit-title {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0px;
  color: #101010;
  text-transform: none;
  font-weight: 700;
  font-style: normal;
  position: relative;
}
.product-item {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.product-item .service-img-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 20px 0 20px;
}
.service-img-wrapper .featured-wrapper img {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.product-item:hover .featured-wrapper img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.product-item .box-content {
  padding-left: 20px;
  padding-bottom: 25px;
}
.product-item .box-content-inner {
  position: relative;
  margin-top: -50px;
  padding: 40px 30px 40px 50px;
  background-color: #fff;
  box-shadow: 0 4px 40px 0 rgb(92 107 149 / 8%);
  border-radius: 20px 0 20px;
}
.product-item .box-content-inner:before {
  position: absolute;
  content: "";
  left: 30px;
  width: 10px;
  margin-top: 10px;
  height: 10px;
  background-color: #8cc63f;
  border-radius: 50%;
}
.product-item .service-title {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 10px;
}
.product-item .service-title a {
  color: #101010;
  transition: all 0.5s ease-out 0s;
}
.product-item:hover .service-title a {
  color: #8cc63f;
}
.service-content p {
  color: #60626d;
  font-size: 16px;
  line-height: 25px;
  margin: 0px 0px 20px 0px;
}
.service-btn {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-style: normal;
  display: inline-block;
  padding: 15px 35px;
  background-color: transparent;
  border: 2px solid #eff3f5;
  border-radius: 20px 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.service-btn a {
  position: relative;
  padding-right: 42px;
  color: #000;
  transition: all 0.5s ease-out 0s;
}
.service-btn a span svg {
  font-size: 25px;
  margin-left: 10px;
}
.service-btn:hover {
  border-radius: 0 20px 0 0;
  border-color: transparent;
  background-color: #8cc63f;
}
.service-btn:hover a {
  color: #fff;
}
/* service section end */
/* fun section start */

.fun-section {
  position: relative;
  overflow: hidden;
  padding: 67px 0px 80px 0px;
}
.centred {
  text-align: center;
}
.green-bg {
  background-color: #56b13d;
}
.fun-section .big-text {
  position: absolute;
  left: 0px;
  bottom: -20px;
  width: 100%;
  text-align: center;
  font-size: 145px;
  line-height: 150px;
  font-family: "kanit"!important;
  text-transform: uppercase;
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.15);
  letter-spacing: 5px;
}
.fun-section .bg-layer .bg-1 {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 375px;
  background-repeat: no-repeat;
  background-image: url(https://st.ourhtmldemo.com/new/Energo/assets/images/icons/vector-1.png);
}
.fun-section .bg-layer .bg-2 {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 334px;
  background-repeat: no-repeat;
  background-image: url(https://st.ourhtmldemo.com/new/Energo/assets/images/icons/vector-2.png);
}
.fun-section .inner-box {
  position: relative;
  display: block;
  padding: 0px 15px;
}
.inner-box .count-outer {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 60px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 4px;
}
.inner-box h5 {
  display: block;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 13px;
}
.inner-box .icon-box {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 66px;
  background-color: #fff;
  font-size: 36px;
  color: #1e2434;
  text-align: center;
  border-radius: 50%;
  transition: all 500ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.statements-section .tab-btn-box .tab-btns li.active-btn {
  background-color: #1e2434;
}

/* fun section end */
/* association sec css start */

.association-sec {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}
.certifications-sec {
  padding-bottom: 120px;
}

/* association sec css end */

/* statements sec css start */

.statements-section {
  background-image: url(./images/statements-new-bg.jpg);
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 120px;
}
.statements-section:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 1) 70%
  );
  top: 0px;
  right: 0px;
  transition: all 500ms ease;
}

/* statements sec css end */
/* partner sec css start */

/* .certifications-sec {
  padding: 30px 0px;
  background-color: #f5f5f5;
} */
.borders-sec {
  border: 4px solid #8cc63f;
  padding: 40px 10px 20px;
  margin-bottom: 40px;
}
.borders-sec h2 {
  background: #8cc63f;
  color: #fff;
  width: 269px;
  text-transform: capitalize;
  text-align: center;
  padding: 5px 0px;
  margin: -69px auto 0px;
}
.certifications-logo .item img {
  width: 100% !important;
  /* height: 170px; */
  object-fit: contain;
}
.certifications-logo .owl-nav {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.certifications-logo button.owl-next {
  background: #8cc63f !important;
  color: #fff !important;
  width: 35px;
  height: 35px;
  font-size: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 2px;
}
.certifications-logo button.owl-prev {
  background: #8cc63f !important;
  color: #fff !important;
  width: 35px;
  height: 35px;
  font-size: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 2px;
}

/* partner sec css end */
/* tab css start */

.tab-sec {
  position: relative;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: -40px;
  z-index: 1;
}
.tab-sec .nav-tabs {
  margin-bottom: 120px !important;
  border: none;
}
.tab-sec .nav-tabs .nav-link {
  background-color: #fff;
  padding: 20px 40px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 15%);
  text-transform: uppercase;
  margin-right: 5px;
}
.tab-sec .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #1e2434 !important;
  border-color: #1e2434 !important;
}
.tab-sec .tab-content .active {
  transform: scale(1) translateY(0px);
}
.statements-section .content-box {
  border-top: 6px solid #56b13d;
  position: relative;
  display: block;
  margin-left: 40px;
  background-color: #fff;
  padding: 52px 30px 59px 60px;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 15%);
}
.statements-section .content-box h5 {
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #56b13d;
  margin-bottom: 7px;
}
.statements-section .content-box h3 {
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 24px;
}

/* tab css end */

/* product page css start */

.product-inner-page {
  padding: 70px 0px;
}
.product-inner-page:nth-last-child(3) {
  padding-bottom: 160px;
}
.product-inner-page:nth-child(odd) {
  background-color: #fff;
}

.product-inner-page:nth-child(even) {
  background-color: #eff3f5;
}

.product-inner-page .accordion-item {
  border: none;
  border-radius: 20px 0px !important;
  margin-bottom: 20px;
}
.product-inner-page img {
  width: 100%;
}
.product-inner-title h2 {
  color: #232323;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
}
.product-inner-title h2:nth-child(even) {
  background-color: red;
}
.product-inner-title p {
  color: #60626d;
  font-size: 16px;
  line-height: 25px;
  margin: 0px 0px 25px 0px;
}
.product-inner-page .accordion-button:not(.collapsed) {
  background-color: #000;
  color: #fff;
  font-weight: 500;
  box-shadow: none;
  border-radius: 20px 0px !important;
}
.product-inner-page button:not(:disabled) {
  box-shadow: 0 0 20px 0 rgb(92 107 149 / 11%);
  border-radius: 20px 0px !important;
}
.product-inner-page .accordion-button:hover {
  background-color: #000;
  color: #fff;
}
.product-inner-page .accordion-body {
  color: #666;
  line-height: 26px;
  background: #baf2592e;
}
.product-inner-page .accordion-button::after {
  background-image: none !important;
}

.product-inner-page .accordion-button::after {
  font-family: "kanit"!important;
  content: "\2b" !important;
  font-size: 20px;
  font-weight: 600;
  vertical-align: middle;
  color: #8cc63f;
}
.product-inner-page .accordion-button:not(.collapsed)::after {
  background-image: none !important;
  content: "\f068" !important;
  transform: none;
  font-weight: 900;
}
#scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000;
}
#scroll-bar::-webkit-scrollbar {
  width: 12px;
  background-color: #000;
  border-radius: 10px;
}
#scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #8cc63f;
}
.accorHeight {
  height: 400px;
  overflow-y: scroll;
  padding-right: 12px;
}

/* product page css end */
/* who we are css start */

.challenges {
  padding: 90px 0px 80px;
  background-color: #fff;
}
.challenges .img-box {
  margin-right: 20px;
}
.challenges .img-box img {
  width: 100%;
  border-radius: 50px 0px;
}
.challenges-content ul li {
  position: relative;
  display: block;
  padding-left: 38px !important;
  font-size: 17px;
  margin-bottom: 12px !important;
}
.challenges-content ul li:before {
  position: absolute;
  content: "";
  background-image: url(https://st.ourhtmldemo.com/new/Energo/assets/images/icons/check-1.png);
  width: 18px;
  height: 18px;
  left: 0px;
  top: 3px;
  background-repeat: no-repeat;
}

/* who we are css end */
/* subscribe section start */
.subscribe-section {
  background-image: url(https://st.ourhtmldemo.com/new/Energo/assets/images/background/subscribe-bg.jpg);
  position: relative;
  padding-top: 102px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.subscribe-section:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(
    -90deg,
    rgba(30, 36, 52, 0) 20%,
    rgba(30, 36, 52, 1) 80%
  );
  top: 0px;
  right: 0px;
  left: 0px;
  transition: all 500ms ease;
}
.subscribe-section h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 45px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 14px;
  text-transform: uppercase;
}
.product-inner-title h2:nth-child(even) {
  text-align: right;
}
.subscribe-section p {
  color: #aab0bf;
  margin-bottom: 45px;
  position: relative;
}
.subscribe-section .subscribe-icon svg {
  font-size: 70px;
  color: #fff;
  margin-bottom: 32px;
  position: relative;
}
.subscribe-section .form-group {
  position: relative;
  margin: 0px;
}
.subscribe-section .form-group svg {
  position: absolute;
  top: 24px;
  right: 20px;
  font-size: 16px;
  color: #707582;
  z-index: 1;
}
.subscribe-section .form-group input {
  position: relative;
  display: block;
  width: 100%;
  height: 64px;
  background-color: #fff;
  border: 2px solid #fff;
  font-size: 15px;
  color: #707582;
  padding: 10px 20px;
  padding-right: 50px;
  transition: all 500ms ease;
}
.subscribe-section .form-group input:focus {
  border-color: #56b13d;
  outline: none;
}
.subscribe-btn button {
  width: 100%;
  padding: 19px 32px;
  background-color: #56b13d;
  border: none;
  color: #fff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.8px;
  font-weight: 600;
  text-align: center;
  border-radius: 0px;
  text-transform: uppercase;
  z-index: 1;
  transition: all 500ms ease;
}
.subscribe-btn button svg {
  font-size: 20px;
  margin-right: 8px;
}
.subscribe-btn button:before {
  background-color: #fff;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: 110%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}
.subscribe-btn button:hover:before {
  top: -40%;
}
.subscribe-btn button:hover {
  color: #1e2434 !important;
}

/* subscribe section end */

/* branches sec css start */

.branches-sec {
  padding: 80px 0px;
  background-color: #eff3f5;
}
.branch-card {
  background: #fff;
  padding: 25px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px #00000045;
  margin-bottom: 28px;
}
.branch-card h4 {
  font-size: 18px;
  margin-bottom: 20px;
}
.branch-card ul {
  margin-bottom: 10px !important;
}
.branch-card ul li {
  padding: 0 0 8px 0 !important;
  display: flex;
  align-items: center;
}
.branch-card ul li svg {
  color: #8cc63f;
  font-size: 22px;
  margin-right: 12px;
  min-width: 22px;
  min-height: 22px;
}
.branch-card ul li p {
  font-size: 14px;
  color: #60626d;
  margin: 0px;
}
.branch-card a {
  background: #8cc63f;
  border: none;
  padding: 5px 13px;
  color: #fff;
  font-weight: 500;
  margin-top: 20px;
}
.branch-card a svg {
  margin-right: 3px;
}
.branch-card a:hover {
  color: #fff;
}

/* branches sec css end */
/* who we are css start */

.who-we-are {
  background-color: #1e2434;
  padding: 110px 0px 90px;
}
.who-we-are-img img {
  height: 326px;
  width: 100%;
  object-fit: cover;
}
.working-block-three {
  position: relative;
  padding-top: 10px;
  display: flex;
  height: 100%;
}
.working-block-three:before {
  position: absolute;
  content: "";
  background-color: #313b56;
  width: 100%;
  height: 5px;
  left: 0px;
  top: 0px;
}
.working-block-three:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 5px;
  left: 0px;
  top: 0px;
  transform: scaleX(0);
  transition: all 500ms ease;
  background-color: #56b13d;
}
.working-block-three:hover:after {
  transform: scaleX(1);
}
.working-block-three .inner-box {
  position: relative;
  display: block;
  background-color: #232a3c;
  padding: 40px 30px 34px 40px;
  margin-bottom: 30px;
}
.working-block-three .inner-box svg {
  font-size: 40px;
  color: #fff;
  margin-bottom: 17px;
}
.working-block-three .inner-box .icon-box {
  position: relative;
  display: block;
  margin-bottom: 18px;
}
.working-block-three .inner-box h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 13px;
  margin-bottom: 23px;
}
.working-block-three .inner-box h5:before {
  position: absolute;
  content: "";
  background-color: #56b13d;
  width: 11px;
  height: 1px;
  left: 0px;
  bottom: 0px;
}
.working-block-three .inner-box h5:after {
  position: absolute;
  content: "";
  background-color: #56b13d;
  width: 50px;
  height: 1px;
  left: 19px;
  bottom: 0px;
}
.working-block-three .inner-box p {
  color: #aab0bf;
  font-size: 14px;
}
.who-we-are-img .text {
  background-color: #56b13d;
  position: relative;
  display: block;
  padding: 32px 40px 33px 30px;
}
.who-we-are-img .text h3 {
  display: block;
  font-size: 30px;
  line-height: 36px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

/* who we are css end */

/* contact us page css start */

.title-bar-wrapper {
  background-image: url(./images/banner-1.jpg);
  position: relative;
  padding: 110px 0px 15px 0px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.title-bar-wrapper:before {
  position: absolute;
  content: "";
  background: #1e2434;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.2;
}
.title-bar-wrapper .title {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 60px;
}
.title-bar-wrapper .title h1 {
  position: relative;
  display: block;
  font-size: 60px;
  line-height: 70px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
}
.title-bar-wrapper .bread-crumb {
  position: relative;
  display: flex;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.title-bar-wrapper .bread-crumb:before {
  position: absolute;
  content: "[";
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  left: 0px;
  top: 0px;
}
.title-bar-wrapper .bread-crumb:after {
  position: absolute;
  content: "]";
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  left: 157px;
  top: -1px;
}
.title-bar-wrapper .bread-crumb.our-company:after{
  left: 200px;
}

.title-bar-wrapper .bread-crumb li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding-right: 15px !important;
  text-transform: uppercase;
}
.title-bar-wrapper .bread-crumb li a {
  color: #fff;
}
.contact-section {
  background-color: #fff;
  padding-top: 100px;
  padding-bottom: 80px;
}
.pbmit-heading-desc {
  color: #60626d;
  margin-top: 15px;
}
.contact-box {
  align-items: center;
  position: relative;
  padding: 35px 35px 25px;
  background-color: #fff;
  border-radius: 20px 0;
  box-shadow: 0 0 20px 0 rgb(92 107 149 / 11%);
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}
.contact-box a,.contact-box a:hover{
  color:inherit;
}
.contact-box:hover {
  background-color: #000;
  color: #fff;
}
.contact-box-icon svg {
  font-size: 70px;
  color: #8cc63f;
}
.contact-box-content {
  padding-left: 20px;
}
.contact-box-content h2 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 5px;
}
.contact-box-content p {
  margin: 0px;
}
.bg-contact-left {
  background-color: #8cc63f;
}
.bg-contact-left .map-sec {
  padding: 100px 113px 100px 260px;
}
.bg-contact-left .map-sec iframe {
  border-radius: 20px;
  height: 542px;
}
.bg-contact-right {
  background-color: #eff3f5;
}
.bg-contact-right .contact-form {
  background-color: #fff;
  padding: 55px 60px 55px 60px;
  margin: 100px 15px 100px 0px;
  border-radius: 20px;
  margin-left: -112px;
  width: 740px;
}
.bg-contact-right .contact-form .form-control {
  width: 100%;
  padding: 15px 15px;
  font-size: 15px;
  background-color: #fff;
  color: #9a9b9c;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  font-weight: 500;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.bg-contact-right .contact-form .form-control:focus {
  outline: none !important;
  border-color: #8cc63f;
  box-shadow: none !important;
}
.bg-contact-right .contact-form .header-button a {
  padding: 18px 36px;
  font-size: 14px;
  min-width: 213px;
}

/* contact us page css end */

/* gallery page css start */

.gallery-sec {
  padding: 90px 0px 160px;
}

.gallery-img img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

#light-box-img {
  height: 400px;
  /* max-width: 400px; */
  object-fit: cover;
}

#light-box {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#light-box button {
  border: none;
  background: #8cc63f;
  color: #fff;
  font-size: 30px;
  width: 50px;
  height: 50px;
}

/* gallery page css end */

/* Career page css start */

.contact-section .form-inner {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 51px 60px 60px 60px;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
}
.contact-section .form-inner .form-control {
  width: 100%;
  padding: 15px 15px;
  font-size: 15px;
  background-color: #fff;
  color: #9a9b9c;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  font-weight: 500;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-inner .header-button a {
  width: 170px;
  height: 58px;
  padding: 15px 34px;
  font-size: 17px;
}
.contact-section .form-inner .form-control:focus {
  outline: none !important;
  border-color: #8cc63f;
  box-shadow: none !important;
}

/* Career page css end */
/* footer css start */

.main-footer {
  position: relative;
  background-image: url(./images/footer.png);
  width: 100%;
  padding: 108px 0px 30px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.main-footer::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000000cc;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.main-footer .support-box {
  position: relative;
  padding-left: 45px;
  margin-bottom: 16px;
}
.main-footer .support-box svg {
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 35px;
  color: #56b13d;
  line-height: 30px;
}
.main-footer .support-box h6 {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  margin: 0px;
  font-weight: 500;
  text-transform: uppercase;
}
.main-footer .support-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #aab0bf !important;
  text-transform: uppercase;
}
.main-footer .text p {
  color: #fff;
}
.main-footer .text h3 {
  color: #fff;
  font-weight: 700;
}
.links-widget .widget-title {
  position: relative;
  display: block;
  padding-bottom: 13px;
  margin-bottom: 20px;
}
/* .links-widget .widget-title:before {
  position: absolute;
  content: "";
  width: 11px;
  height: 1px;
  left: 0px;
  bottom: 0px;
  background-color: #56b13d;
}
.links-widget .widget-title:after {
  position: absolute;
  content: "";
  width: 50px;
  height: 1px;
  left: 19px;
  bottom: 0px;
  background-color: #56b13d;
} */
.links-widget .widget-title h5 {
  display: block;
  font-size: 28px;
  line-height: 28px;
  color: #8cc63f;
  font-weight: 600;
  margin: 0px;
}
.link-extra {
  text-align: right;
  padding-right: 30px;
  border-right: 2px solid #fff3;
}
.links-widget .links-list li {
  position: relative;
  display: block;
  margin-bottom: 10px !important;
}
.links-widget .links-list li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
  padding-left: 32px;
}
.links-widget .links-list li a:before {
  position: absolute;
  content: "";
  background-image: url(https://st.ourhtmldemo.com/new/Energo/assets/images/icons/check-1.png);
  width: 18px;
  height: 18px;
  left: 0px;
  top: 3px;
  background-repeat: no-repeat;
}
.link-extra .links-list li a:before {
  display: none;
}
.link-extra svg {
  color: #56b13d;
  font-size: 36px;
}
.link-extra .links-list li {
  margin-bottom: 20px !important;
}
.link-extra ul li a {
  font-size: 19px !important;
  padding-left: 12px !important;
  font-weight: 300 !important;
}
.link-extra ul li svg {
  font-size: 23px;
  margin-top: -6px;
}
.link-extra p {
  margin-bottom: 40px;
}
.link-extra p a {
  color: #fff;
  font-size: 25px !important;
  padding-left: 12px !important;
}
/* 
.links-widget .links-list li a:after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  left: 6px;
  top: 13px;
  transition: all 500ms ease;
  background-color: #56b13d;
} */
.links-widget .links-list li a:hover:after {
  width: 15px;
}
.links-widget .links-list li a:hover {
  color: #56b13d;
}
.post-widget .post-inner {
  margin-bottom: 17px;
}
.footer-top .post-widget .post {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.post-widget h6 a:hover {
  color: #56b13d;
}
.post-widget .post .post-date {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: #aab0bf;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.post-widget h6 {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}
.post-widget h6 a {
  display: inline-block;
  color: #fff;
}
.post-widget .links h6 {
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}
.post-widget .links h6 a {
  display: inline-block;
  color: #fff;
}
.post-widget .links h6 a svg {
  margin-right: 8px;
  font-size: 20px;
  position: relative;
  top: 0px;
  color: #56b13d;
}
.post-widget h6 a:hover {
  color: #56b13d;
}
.footer-bottom {
  position: relative;
  background-color: #1e2434;
  width: 100%;
  padding: 22px 0px;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.copyright p {
  color: #aab0bf;
  margin: 0px;
}
.copyright p a {
  color: #aab0bf;
}
.copyright p a:hover {
  color: #fff;
}
.footer-nav li {
  position: relative;
  /* display: inline-block; */
  float: left;
  margin-right: 25px !important;
}
.footer-nav li a {
  display: inline-block;
  color: #aab0bf;
}
.footer-nav li a:hover {
  color: #fff;
  text-decoration: underline;
}
.quote-sec {
  background: linear-gradient(45deg, #56b13d, #baf259);
  padding: 50px 60px;
}
.quote-sec {
  color: #fff;
  margin-top: -200px;
  margin-bottom: 100px;
  position: relative;
}
.quote-sec .subscribe-btn button {
  background-color: #000;
}
.footer-logo img {
  width: 90px;
  margin-bottom: 20px;
}
/* footer css end */

.products-btn a {
  box-shadow: 0 0 20px 0 rgb(92 107 149 / 11%);
  border-radius: 20px 0px !important;
  width: 100%;
  padding: 7px 14px;
  font-size: 13px;
  background-color: #56b13d;
  border: none;
  color: #fff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  line-height: 26px;
  letter-spacing: 0.8px;
  font-weight: 600;
  text-align: center;
  border-radius: 0px;
  text-transform: uppercase;
  z-index: 1;
  transition: all 500ms ease;
}
.products-btn.faq {
  text-align: right;
  margin-top: 15px;
}
.products-btn.faq a {
  width: 200px;
}
.products-btn a:before {
  background-color: #fff;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}
.products-btn a:hover:before {
  top: -40%;
  background-color: #1e2434;
}
.products-btn a:hover {
  color: #fff;
}
/* Desktops: */

@media (max-width: 1920px) {
}
@media (max-width: 1680px) {
  .bg-contact-left .map-sec {
    padding: 100px 113px 100px 200px;
  }
}

/* Laptops: */

@media (max-width: 1440px) {
  .banner .carousel-caption h2 {
    font-size: 37px;
    line-height: 50px;
  }
  .bg-contact-left .map-sec {
    padding: 100px 100px 100px 80px;
  }
  .bg-contact-right .contact-form {
    margin-left: -90px;
    width: 720px;
  }
  .contact-box-icon svg {
    font-size: 55px;
  }
}
@media (max-width: 1366px) {
  .pbmit-heading-subheading .pbmit-title {
    font-size: 35px;
    line-height: 42px;
  }
  .about_content h2 {
    font-size: 32px;
  }
  .bg-contact-left .map-sec {
    padding: 100px 95px 100px 50px;
  }
}
@media (max-width: 1280px) {
  .bg-contact-right .contact-form {
    width: 100%;
    margin: 0px;
    padding: 40px 45px;
  }
  .bg-contact-left {
    padding: 60px 20px !important;
  }
  .bg-contact-left .map-sec {
    padding: 0px;
  }
  .bg-contact-left .map-sec iframe {
    height: 510px;
  }
  .bg-contact-right {
    padding: 60px 20px !important;
  }
  .bg-contact-right .contact-form {
    width: 100%;
    margin: 0px;
    padding: 40px 45px;
  }
}

/* Tablets: */

@media (max-width: 1024px) {
  .header-button a {
    padding: 20px 40px;
  }
  .header-box {
    margin-right: 7px;
    padding: 0px 0px 0px 56px;
  }
  .header-box-title {
    font-size: 15px;
  }
  .header-box-content {
    font-size: 16px;
  }
  .navigation .nav-link {
    margin-right: 10px;
    font-size: 15px;
  }
  .banner .carousel-caption h2 {
    font-size: 37px;
    line-height: 50px;
  }
  .banner .carousel-item {
    height: 500px;
  }
  .banner .carousel-caption {
    bottom: 6rem;
  }
  .banner .carousel-control-prev {
    left: 82% !important;
  }
  .banner .carousel-control-next {
    right: 7% !important;
  }
  .banner .carousel-control-next,
  .carousel-control-prev {
    top: 75% !important;
  }
  .about_content h3.heading {
    margin: 0px 0 0;
  }
  .about_content h2 {
    font-size: 28px;
    margin: 8px 0px 15px;
  }
  .about_content {
    padding: 0px;
  }
  .about_content h1.sub-heading {
    font-size: 80px;
    left: -16px;
  }
  .contact-box {
    padding: 18px 25px 25px;
    align-items: center;
  }
  .contact-box-icon svg {
    font-size: 42px;
  }
  .bg-contact-right .contact-form {
    padding: 40px 35px;
  }
}
@media (max-width: 991px) {
  .navbar .navbar-toggler {
    background-color: #8cc63f;
  }
  .contact-box {
    margin-bottom: 20px;
  }
  .menu-right-box {
    display: none;
  }
  .header .site-header-menu {
    background: #4e4e4e;
  }
  .navigation:before {
    background: #4e4e4e;
  }
  .tab-sec .nav-tabs .nav-link {
    padding: 20px 20px;
  }
  .who-we-are-img {
    margin-bottom: 25px;
  }
  .challenges .img-box {
    margin: 0px 0px 25px 0px;
  }
  .site-branding {
    display: none;
  }
  .navbar-brand img {
    display: block;
  }
  .header-box {
    padding: 0px 0px 0px 40px;
  }
  .header-icon svg {
    left: 0px;
  }
  .header-info {
    padding: 15px 0px;
  }
  .banner .carousel-caption h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .navbar-collapse {
    margin-top: 45px;
  }
  .title-bar-wrapper .title h1 {
    font-size: 47px;
    line-height: 54px;
  }
  .accorHeight {
    margin-bottom: 30px;
  }
  .product-inner-page img {
    margin-bottom: 30px;
  }
  .product-inner-title h2 {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 767px) {
  .quote-sec {
    padding: 20px 15px;
    flex-direction: column;
    text-align: center;
  }
  .link-extra {
    text-align: left;
    padding-right: 0px;
    border-right: 0px;
  }
  .links-widget .widget-title {
    padding-bottom: 0px;
  }
  .link-extra svg {
    font-size: 24px !important;
  }
  .link-extra p a {
    font-size: 18px !important;
  }
  .link-extra p {
    margin-bottom: 10px;
  }
  .link-extra ul li a {
    font-size: 18px !important;
  }
  .link-extra .links-list li {
    margin-bottom: 10px !important;
  }
  .link-extra {
    margin-bottom: 25px;
  }
  .links-widget .widget-title h5 {
    font-size: 23px;
    line-height: 17px;
  }
  .contact-section .form-inner {
    padding: 51px 50px 50px 50px;
  }
}
/* Mobile phones: */

@media (max-width: 640px) {
  .service-section .center-align {
    width: 70%;
  }
  .pbmit-heading-subheading .pbmit-title {
    font-size: 31px;
    line-height: 36px;
  }
  .bg-contact-left .map-sec iframe {
    height: 375px;
  }
}
@media (max-width: 480px) {
  .service-section .center-align {
    width: 88%;
  }
}
@media (max-width: 430px) {
  #light-box-img {
    height: 300px;
    max-width: 300px;
  }
  .product-inner-title h2 {
    font-size: 22px;
    margin-bottom: 12px !important;
  }
  .product-inner-title p {
    font-size: 15px;
    line-height: 24px;
  }
  .product-inner-page button:not(:disabled) {
    padding: 14px 14px;
  }
  .product-inner-page .accordion-body {
    line-height: 24px;
    font-size: 15px;
  }
  .title-bar-wrapper {
    padding: 70px 0px 15px 0px;
  }
  .title-bar-wrapper .title {
    margin-bottom: 25px;
  }
  .header {
    background-color: #000;
  }
  .certifications-logo .item img {
    height: auto;
  }
  .title-bar-wrapper .title h1 {
    font-size: 33px;
    line-height: 40px;
  }
  .service-btn {
    padding: 12px 0px 12px 20px;
  }
  .service-btn a span svg {
    margin-left: 6px;
  }
  .banner .carousel-item:after {
    border: none;
  }
  .header-info {
    padding: 4px 0px;
    text-align: center;
  }
  .header-box {
    padding: 0px 0px 0px 26px;
    margin-right: 39px;
  }
  .header-box-content {
    font-size: 13px;
    color: #fff;
  }
  .header-box-title {
    display: none !important;
  }
  .header-icon svg {
    font-size: 18px;
    top: 2px;
    color: #fff;
  }
  .link-extra {
    padding-left: 6px;
  }
  .footer-logo {
    padding-left: 6px;
  }
  .links-widget {
    padding-left: 6px;
  }
  .copyright-text {
    padding-left: 6px;
    padding-top: 15px;
  }
  .main-footer .text {
    padding-left: 6px;
  }
  .header-box:nth-child(2) {
    margin-right: 0px;
  }
  .header-box:last-child {
    padding: 0px 0px 0px 26px;
  }
  .banner .carousel-item {
    height: 400px;
  }
  .banner .carousel-caption h2 {
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
  }
  .banner .carousel-caption h6 {
    margin: 30px 0px;
    font-size: 15px;
    text-transform: capitalize;
    line-height: 21px;
  }
  .slider-btn button {
    padding: 12px 12px;
    font-size: 13px;
  }
  .banner .carousel-caption {
    bottom: 4rem;
  }
  .banner .carousel-control-prev {
    left: 74% !important;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 1.4rem !important;
    height: 1.4rem !important;
  }
  .banner .carousel-control-next,
  .carousel-control-prev {
    width: 35px !important;
    height: 35px;
    top: 88% !important;
  }
  .about_content h1.sub-heading {
    font-size: 44px;
    left: 6px;
    top: -7px;
  }
  .about_content h2 {
    font-size: 22px;
  }
  .about_content p {
    font-size: 14px;
  }
  .pbmit-heading-subheading .pbmit-title {
    font-size: 20px;
    line-height: 25px;
  }
  .quote-sec h3 {
    font-size: 20px;
  }
  .branch-card h4 {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .main-footer .text p {
    font-size: 15px;
    margin-bottom: 26px;
    line-height: 20px;
  }
  .quote-sec p {
    font-size: 14px;
    line-height: 19px;
  }
  .tab-sec .nav-tabs {
    margin-bottom: 40px !important;
    justify-content: center;
  }
  .tab-sec .nav-tabs .nav-link {
    padding: 15px 12px;
    text-transform: capitalize;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .tab-sec {
    margin-top: -24px;
  }
  .statements-section .content-box {
    padding: 30px 30px;
    margin-left: 0px;
  }
  .statements-section .content-box h3 {
    font-size: 19px;
    line-height: 16px;
    text-transform: capitalize;
    margin-bottom: 19px;
  }
  .content-box p {
    font-size: 15px;
  }
  .working-block-three .inner-box {
    padding: 40px 25px 25px 25px;
  }
  .challenges-content {
    margin-top: 1.5rem;
  }
  .challenges-content ul li {
    padding-left: 30px !important;
    font-size: 15px;
    margin-bottom: 5px !important;
  }
  .statements-section {
    padding-bottom: 75px;
  }
  .product-item .service-title {
    font-size: 17px;
    line-height: 28px;
  }
  .subscribe-btn button {
    padding: 12px 12px;
    font-size: 13px;
  }
  .links-widget .widget-title h5 {
    font-size: 19px;
    line-height: 12px;
  }
  .link-extra svg {
    font-size: 18px !important;
  }
  .link-extra p a {
    font-size: 15px !important;
  }
  .link-extra ul li a {
    font-size: 15px !important;
  }
  .link-extra .links-list li {
    margin-bottom: 6px !important;
  }
  .links-widget .links-list li a:before {
    width: 15px !important;
  }
  .links-widget .links-list li a {
    font-size: 15px;
    line-height: 26px;
    padding-left: 27px;
    font-weight: 400;
  }
  .who-we-are-img .text {
    padding: 17px 20px 12px 20px;
  }
  .who-we-are-img .text h3 {
    font-size: 21px;
  }
  .links-widget .links-list li {
    margin-bottom: 4px !important;
  }
  .who-we-are-img img {
    height: 280px;
  }
  .working-block-three .inner-box svg {
    margin-bottom: 7px;
  }
  .working-block-three .inner-box h5 {
    font-size: 16px;
  }
  .who-we-are-img img {
    height: 250px;
  }
  .service-section .center-align {
    width: 100%;
  }
  .contact-section .form-inner {
    padding: 51px 25px 50px 25px;
  }
  .contact-box {
    padding: 18px 15px 20px;
  }
  .contact-box-content h2 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 3px;
  }
  .contact-box-content p {
    font-size: 15px;
  }
  .form-inner .header-button a {
    font-size: 15px;
    line-height: 29px;
  }
  .bg-contact-left .map-sec iframe {
    height: 330px;
  }
  .pbmit-heading-desc {
    font-size: 15px;
  }
  .bg-contact-right .contact-form {
    padding: 40px 25px;
  }
  #light-box button {
    font-size: 17px;
    width: 35px;
    height: 35px;
  }
  .title-bar-wrapper .bread-crumb {
    margin-left: 25px !important;
  }
  .branches-sec {
    padding: 50px 0px;
  }
  .service-section .center-align {
    margin-bottom: 35px;
  }
  .association-sec {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .service-section {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .who-we-are {
    padding: 80px 0px 40px;
  }
  .about-sec {
    padding: 50px 0px;
  }
}
@media (max-width: 414px) {
  #light-box-img {
    height: 270px;
    max-width: 270px;
  }
}
@media (max-width: 375px) {
  .product-inner-title h2 {
    font-size: 20px;
    margin-bottom: 10px !important;
  }
  .banner .carousel-control-prev {
    left: 72% !important;
  }
  .working-block-three .inner-box {
    padding: 30px 10px 10px 25px;
  }
  .pbmit-heading-subheading .pbmit-title {
    font-size: 18px;
    line-height: 23px;
  }
  .statements-section .content-box {
    padding: 21px 18px;
  }
  .product-item .box-content-inner:before {
    left: 20px !important;
  }
  .product-item .service-title {
    font-size: 16px;
  }
  .product-item .box-content-inner {
    padding: 30px 16px 30px 35px;
  }
  .bg-contact-right .contact-form {
    padding: 40px 15px;
  }
}
@media (max-width: 320px) {
}
